<template>
  <div>
    <div :class="$style.block">
      <div class="row">
        <div class="col-xl-12">
          <div :class="$style.inner">
            <div :class="$style.loginForm">
              <h4 :class="$style.loginFormTitle">
                2段階認証
              </h4>
              <a-form class="login-form" :form="form" @submit="handleSubmit">
                <a-form-item label="コード">
                  <a-input
                    placeholder="code"
                    v-decorator="['code', { rules: [{ required: true, message: 'コードは必須です。' }]}]"
                  >
                    <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25);"/>
                  </a-input>
                </a-form-item>
                <div class="pb20">
                  <p><router-link
                    class="pull-right"
                    to="/user/login"
                    :class="$style.forgetLink"
                  >戻る</router-link></p>
                </div>
                <div class="form-actions" style="text-align: center;">
                  <a-button
                    htmlType="submit"
                    :class="[$style.filledBtn, $style.loginBtn]"
                  >ログイン</a-button>
                </div>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
import router from '@/router'

export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          // user_id: this.$route.params.user_id,
          this.$nprogress.start()
          this.$auth.two_fa(this.$route.params.user_id, values.code)
            .then(() => {
              // this.$nprogress.done()
              // this.$notification['success']({
              //   message: 'Logged In',
              //   description: 'You have successfully logged in to Clean UI Vue Admin Template!',
              // })
            })
            .catch((error) => {
              this.$nprogress.done()
              this.$notification['warning']({
                message: error.status + ': ' + error.data.data,
              })
              if (error.data.code === 2) {
                router.push('/user/login')
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
